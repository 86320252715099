import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loaderSubject = new Subject<boolean>();
  public loaderState$ = this.loaderSubject.asObservable();
  private minDisplayTime = 1000; // Tempo minimo di visualizzazione in millisecondi
  private timeoutId: any;
  private loadingStartTime: number | undefined;

  showLoader() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.loaderSubject.next(true);
    this.loadingStartTime = Date.now();
  }

  hideLoader() {
    const elapsedTime = Date.now() - this.loadingStartTime!;
    const remainingTime = Math.max(0, this.minDisplayTime - elapsedTime);

    this.timeoutId = setTimeout(() => {
      this.loaderSubject.next(false);
    }, remainingTime);
  }
}
