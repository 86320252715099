import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/api/auth.service';
import { ExceptionHandler } from 'src/app/shared/services/utils/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _exceptionHandler: ExceptionHandler,
    private _authService: AuthService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) {

          if (err.error['Code'] && this._exceptionHandler.errors.findIndex((el) => el.code == err.error['Code']) > -1) {
            return next.handle(request);
          } else {
            this._authService.logout();
          }
          // this._router.navigate(['login']);
        } else if (err.status == 0 || err?.error?.error?.code?.length) {
          // this._errorService.failedErrorSubject.next(err);
        }
        return throwError(() => err);
      })
    );
  }
}
