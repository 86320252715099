<div class="flex flex-column justify-content-center">
  <div class="w-full">
    <h3>{{ message }}</h3>
  </div>
  <div class="w-full flex justify-content-center mt-4" *ngIf="showLoginButton">
    <p-button
      [label]="'common.ok' | transloco"
      [attr.aria-label]="'common.ok' | transloco"
      (onClick)="(null)"
    >
    </p-button>
  </div>
</div>
