import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faDashboard, faMusic, faTicket, faUser, faWrench, faXmark } from '@fortawesome/free-solid-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { TreeNode } from 'primeng/api';
import { RootRoutes } from 'src/app/app-routing.module';
import { LayoutService } from '../../service/app.layout.service';
interface CustomTreeNode extends TreeNode {
  route?: RootRoutes[];
  menuIcon?: IconDefinition;
  children?: CustomTreeNode[];
}
@Component({
  selector: 'app-default-layout',
  templateUrl: './app-default-layout.component.html',
  styleUrls: ['./app-default-layout.component.scss'],
})
export class AppDefaultLayoutComponent {
  public menuItems: CustomTreeNode[] = [];
  public sidebarVisible: boolean = false;
  protected readonly faXmark = faXmark;


  constructor(public layoutService: LayoutService, private _router: Router,
    private _translateService: TranslocoService,) {

    this.menuItems = [
      {
        label: this._translateService.translate('menu.dashboard'),
        route: [RootRoutes.APP, RootRoutes.DASHBOARD],
        menuIcon: faDashboard,
        type: 'url',
      },
      {
        label: this._translateService.translate('menu.events'),
        route: [RootRoutes.APP, RootRoutes.EVENT],
        menuIcon: faTicket,
        type: 'url',
      },

      {
        label: this._translateService.translate('menu.artists'),
        route: [RootRoutes.APP, RootRoutes.ARTISTS],
        menuIcon: faMusic,
        type: 'url',
      },
      {
        label: this._translateService.translate('menu.company'),
        route: [RootRoutes.APP, RootRoutes.COMPANY, RootRoutes.MANAGE],
        menuIcon: faBuilding,
        type: 'url',
      },
      {
        label: this._translateService.translate('menu.settings'),
        menuIcon: faWrench,
        type: 'default',
        children: [
          // {
          //   label: this._translateService.translate('menu.tags'),
          //   route: [RootRoutes.APP,RootRoutes.SETTINGS, RootRoutes.TAGS],
          //   menuIcon: faTags,
          //   type: 'url',
          // },
          {
            label: this._translateService.translate('menu.myProfile'),
            route: [RootRoutes.APP, RootRoutes.SETTINGS, RootRoutes.MY_PROFILE],
            menuIcon: faUser,
            type: 'url',
            leaf: true,
          },
        ],
      },
    ];
  }




  public navigateTo(route: RootRoutes[]) {
    this._router.navigate(route);
  }

  public openMenu(node: TreeNode) {
    if (this.layoutService.state.sideMenuOpen) {
    } else {
      this.layoutService.onMenuToggle()
    }
    this.expandItem(node);
  }

  public expandItem(node: TreeNode) {

    let finded = this.menuItems.find((el) => el == node)
    if (finded)
      finded.expanded = true;
  }


}
