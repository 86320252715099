import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RootRoutes } from 'src/app/app-routing.module';


@Component({
  selector: 'app-exception-alert',
  templateUrl: './exception-alert.component.html',
  styleUrls: ['./exception-alert.component.scss'],
})
export class ExceptionAlertComponent implements OnInit {
  public message: string | undefined;
  public showLoginButton: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    // private dialogService: DialogService,
    private _router: Router
  ) { }
  ngOnInit() {
    if (this.config.data && this.config.data.message) {
      this.message = this.config.data.message;
      this.showLoginButton = this.config.data.showLoginButton;
    }
  }

  // public goToLogin() {
  //   this._router.navigateByUrl(RootRoutes.LOGIN);
  //   this.dialogService.dialogComponentRefMap.forEach((dialog) => {
  //     dialog.destroy();
  //   });
  // }
}
