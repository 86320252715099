import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RootRoutes } from 'src/app/app-routing.module';


export class AuthGuard {
  constructor() { }

  public static canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (
      state.url.includes(RootRoutes.LOGIN) ||
      state.url.includes(RootRoutes.RESETPASSWORD) ||
      state.url.includes(RootRoutes.CONFIRMOTP)
    ) {
      return state.url;
    }

    const jwt = localStorage.getItem('userInfo');
    if (!jwt) {
      const router = inject(Router);
      return router.createUrlTree([RootRoutes.APP, RootRoutes.LOGIN]);
    } else {
      return true;
    }
  }
}
