import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ExceptionAlertComponent } from '../../components/dialog/exception-dialog/exception-alert.component';


@Injectable({
  providedIn: 'root',
})
export class ExceptionHandler {
  constructor(
    private translations: TranslocoService,
    private messagesService: MessageService,
    public dialogService: DialogService
  ) { }

  public errors: { code: string; customTitle?: string }[] = [
    { code: 'ACCESS_DENIED' },
    { code: 'INVALID_MAIL_PWD' },
    { code: "OBJ_NOT_FOUND" },
    { code: 'EX_ALREADY_EMPL' },
    { code: 'EX_CANT_REMOVE_ADMIN' },
    { code: 'EX_EMAIL_PASS_MISMATCH' },
    { code: 'EX_DUPLICATED_ENTITY' },

  ];

  public handle(exception: any, errorMessage?: string) {
    if (exception) {
      if (exception.error && exception.error['Code']) {
        let error: {
          code: string,
          message: string
        } = {
          code: exception.error['Code'] || null,
          message: exception.error['Message'] || null
        };


        if (error && error.code) {
          const findMsg = this.errors.find((e) =>
            e.code.toLowerCase().includes(error.code.toLowerCase())
          );
          if (findMsg) {
            // Casistica particolare, fa visualizzare insieme al messaggio anche un btn per tornare alla login.
            // if (
            //   findMsg.code == 'REGISTRATION_FISCALCODE_VIOLATION' ||
            //   findMsg.code == 'REGISTRATION_FISCALCODE_VIOLATION'
            // ) {
            //   return this.openAlertDialog(
            //     this.translations.translate('exceptions.' + findMsg.code),
            //     true
            //   );
            // }

            return this.openAlertDialog(
              this.translations.translate('exceptions.' + findMsg.code)
            );
          } else {
            // return this.openAlertDialog(
            //   this.translations.translate(error.message)
            // );
          }
        } else if (error && error.message) {
          return this.openAlertDialog(
            this.translations.translate(error.message)
          );
        }
      }
    }

    let errorMsg = errorMessage || 'common.genericError';
    return this.messagesService.add({
      severity: 'error',
      summary: this.translations.translate('common.warning'),
      detail: this.translations.translate(errorMsg),
    });
  }

  openAlertDialog(message: string, backToLogin?: boolean): DynamicDialogRef {
    let showLoginButton = false;
    if (!!backToLogin) showLoginButton = true;

    const config: DynamicDialogConfig = {
      data: { message: message, backToLogin: showLoginButton },
      header: this.translations.translate('common.alert.title'),
      width: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      modal: true,
    };

    return this.dialogService.open(ExceptionAlertComponent, config);
  }
}
