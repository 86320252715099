import { Component } from '@angular/core';
import {
  faBars,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import { LoggedUserInfo } from 'src/app/shared/models/auth';

import { Account } from 'src/app/shared/models/account';
import { AuthService } from 'src/app/shared/services/api/auth.service';
import { environment } from 'src/environments/environments';
import { LayoutService } from '../../service/app.layout.service';

@Component({
  selector: 'app-top-bar',
  styleUrls: ['./app-top-bar.component.scss'],
  templateUrl: './app-top-bar.component.html',
})
export class AppTopBarComponent {
  public loginResponse: LoggedUserInfo | undefined;
  public userInfo: Account | undefined;
  public baseUrl: string;

  protected readonly faSignOutAlt = faSignOutAlt;
  protected readonly faBars = faBars;
  constructor(
    private layoutService: LayoutService,
    private _authService: AuthService
  ) {
    this.baseUrl = environment.apiUrl;
    this.userInfo = this._authService.userInfo.accountInfo;
  }

  public toggleSideBar() {
    this.layoutService.onMenuToggle();
  }

  public onLogout() {
    this._authService.logout();
  }
}
