import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injectable,
  isDevMode,
  NgModule,
} from '@angular/core';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private _http: HttpClient,
    private _primengConfig: PrimeNGConfig
  ) {}

  getTranslation(lang: string) {
    return this._http.get<Translation>(`/assets/i18n/${lang}.json`).pipe(
      tap((translation: Translation) => {
        this._primengConfig.setTranslation(translation['primeng']);
      })
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          {
            id: 'it',
            label: 'Italiano',
          },
        ],
        defaultLang: 'it',
        fallbackLang: 'it',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      deps: [TranslocoService],
      useFactory:
        (translocoService: TranslocoService): any =>
        (): Promise<Translation | undefined> => {
          const defaultLang = translocoService.getDefaultLang();
          translocoService.setActiveLang(defaultLang);
          return translocoService.load(defaultLang).toPromise();
        },
      multi: true,
    },
  ],
})
export class TranslocoRootModule {}
