<p-toolbar styleClass="top-toolbar">
  <div class="flex align-items-center">
    <p-button
      (click)="toggleSideBar()"
      icon="true"
      styleClass="p-button-text icon"
    >
      <fa-icon [icon]="faBars" class="text-neutral-black"></fa-icon>
    </p-button>
    <a class="mx-4" routerLink="/app/events">
      <img
        alt="logo"
        src="./assets/images/logo.svg"
        height="48"
        aria-label="Ehi App"
      />
    </a>
  </div>
  <div class="right-side-container">
    <div class="logged-user-container">
      <div class="logged-user-data" *ngIf="!!userInfo">
        <img [src]="baseUrl + userInfo.imageUrl" />
        <div class="flex flex-column gap-1">
          <div class="nominative">
            {{ userInfo.firstName }}
            {{ userInfo.lastName }}
          </div>
          <div>{{ userInfo.companyName }}</div>
        </div>
      </div>

      <div class="logout">
        <button
          pButton
          class="p-button-text"
          [attr.aria-label]="'menu.logout' | transloco"
          (click)="onLogout()"
        >
          <fa-icon [icon]="faSignOutAlt"></fa-icon>
          <span class="ml-2 font-bold">
            {{ "menu.logout" | transloco }}
          </span>
        </button>
      </div>
    </div>
  </div>
</p-toolbar>
