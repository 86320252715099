import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Account, CreatedPersonModel, PersonInfo } from '../../../models/account';
import { LoggedUserInfo } from '../../../models/auth';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistryService {
  private readonly _baseUrl = 'registry';

  constructor(private _http: HttpClient, private authService: AuthService) { }



  public getMyAccounts() {
    return this._http.get<Account[]>(`${this._baseUrl}/my/account/list`).pipe(tap((res) => {

      let user = res.find((res) => res.accountType == 0);
      this.authService.userInfo = {
        ...this.authService.userInfo,
        accountInfo: user!
      };
    }));
  }

  public getMyAccountToken(accountId: string) {
    return this._http
      .get<LoggedUserInfo>(`${this._baseUrl}/authenticate/${accountId}`)
      .pipe(
        tap((response) => {
          this.authService.token = response.token
          this.authService.userInfo = {
            ...this.authService.userInfo,
            accountInfo: response.accountInfo
          };
        })
      );
  }

  public createNewAccount(personData: PersonInfo) {
    return this._http
      .post<CreatedPersonModel>(`${this._baseUrl}/person/add`,
        {
          ...personData
        })

  }

  public createNewCompany(companyName: string,) {
    return this._http.post(`${this._baseUrl}/company/add`, {
      companyName
    });
  }
}
