import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../models/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private _environment: Environment;

  constructor(@Inject('environment') environment: Environment) {
    this._environment = environment;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      url: this._getRequestUrl(req.url),
    });

    return next.handle(cloned);
  }

  private _getRequestUrl(currentUrl: string) {
    if (currentUrl.startsWith('http') || currentUrl.includes('assets')) {
      return currentUrl;
    }

    return `${this._environment.apiUrl}${
      this._environment.apiUrl.endsWith('/') || currentUrl.startsWith('/')
        ? ''
        : '/'
    }${currentUrl}`;
  }
}
