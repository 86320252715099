import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from "../../service/app.layout.service";

@Component({
  selector: 'app-empty-layout',
  templateUrl: './app-empty-layout.component.html',
  styleUrls: ['./app-empty-layout.component.scss']
})
export class AppEmptyLayoutComponent {
  public isLandingPage: boolean = false; //
  constructor(public layoutService: LayoutService, public router: Router) {

    this.isLandingPage = this.router.url == '/termini-condizioni' || this.router.url == '/privacy-policy' || this.router.url == '/' || this.router.url == '/#home' || this.router.url == '/#chiSiamo' || this.router.url == '/#contattaci'
  }
}
